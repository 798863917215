import React from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import styles from './ContentToggle.module.css';

const ContentToggle = ({ activeCategory, setActiveCategory }) => {
  const handleToggle = (content) => {
    setActiveCategory(content);
  };

  return (
    <div className={styles.contentToggle}>
      <button
        className={
          styles.contentToggleButton +
          (activeCategory === 'highlights' ? ` ${styles.active}` : '')
        }
        onClick={() => {
          sendGTMEvent(GA4_KEY.choose_category('хайлайты'));
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
          handleToggle('highlights');
        }}
      >
        Хайлайты
      </button>
      {/* <button
        className={
          styles.contentToggleButton +
          (activeCategory === 'products' ? ` ${styles.active}` : '')
        }
        onClick={() => {
          sendGTMEvent(GA4_KEY.choose_category('товары'));
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
          handleToggle('products');
        }}
      >
        Товары
      </button> */}
      <button
        className={
          styles.contentToggleButton +
          (activeCategory === 'education' ? ` ${styles.active}` : '')
        }
        onClick={() => {
          sendGTMEvent(GA4_KEY.choose_category('гайды'));
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
          handleToggle('education');
        }}
      >
        Гайды
      </button>
      {/*<button*/}
      {/*  className={styles.contentToggleButton + (activeCategory === 'tips' ? ` ${styles.active}` : '')}*/}
      {/*  onClick={() => {*/}
      {/*    sendGTMEvent(GA4_KEY.choose_category('советы'));*/}
      {/*    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');*/}
      {/*    handleToggle('tips');*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Советы*/}
      {/*</button>*/}
    </div>
  );
};

export default ContentToggle;

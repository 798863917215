import makeRequest from './apiConfig';

const paymentEndpoints = {
  createPayment: '/api/orders/create',
  getPaymentStatus: '/api/orders/status',
};

export const createPayment = async (productName) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: paymentEndpoints.createPayment,
      body: {
        productName: productName,
        cloud_payment: true,
      },
    });
    return response;
  } catch (error) {
    console.error('Failed to create payment', error);
    throw error;
  }
};

export const getPaymentStatus = async (hash) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${paymentEndpoints.getPaymentStatus}?order=${hash}`,
    });

    return response;
  } catch (error) {
    console.error('Failed to get payment status:', error);
    throw error;
  }
};

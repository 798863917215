import React, { useEffect } from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import styles from './OffertPopUp.module.css';

const OffertPopUp = ({ isOpen, onClose, onlyRead = false }) => {
  useEffect(() => {
    if (isOpen) {
      sendGTMEvent(GA4_KEY.open_offert_popup);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <div className={styles.popupHeader}>
          <h2 className={styles.popupTitle}>Лицензионное соглашение Сервиса</h2>
        </div>

        <div className={styles.popupContentWrapper}>
          <div className={styles.popupContent}>
            <h3>1. Общие положения</h3>

            <p>
              1.1. Предметом регулирования настоящего Лицензионного соглашения
              (далее — Соглашение, ЛС) являются отношения между Индивидуальным
              предпринимателем Мороз Татьяной Викторовной (далее — Исполнитель),
              являющимся правообладателем чат-бота, доступного в Telegram по
              ссылке @klavagolmanbot, а также мини-приложения в сервисе Telegram
              (Telegram mini app) и Вами (физическим лицом, являющимся
              пользователем сети Интернет) именуемым в дальнейшем Пользователем,
              по поводу использования Чат-бота и мини-приложения Клавы Гольман
              (далее — Сервис). Сервис позволяет Пользователю получать
              персонализированные рекомендации, ответы на вопросы,
              предоставляемые технологиями искусственного интеллекта,
              осуществлять иное взаимодействие указанными технологиями, а также
              использовать возможности, предоставляемые мини-приложением.
            </p>

            <p>
              1.2. Дополнительные функциональные (программные) возможности
              Сервиса, право использования которых предоставляются Пользователю
              за плату, размер и условия внесения которой регулируются отдельным
              соглашением «Соглашение о порядке предоставления дополнительного
              функционала Чат-бота Клавы Гольман» (далее – «Соглашение о
              предоставлении дополнительного функционала»), являющимся
              неотъемлемой частью настоящего Соглашения.
            </p>

            <p>
              1.3. При регистрации, каждом доступе и/или фактическом
              использовании Сервиса Пользователь безоговорочно соглашается с
              условиями ЛС и с иными условиями положений и правил использования
              Сервиса в редакциях, которые действовали на момент фактического
              использования Сервиса, а также дает согласие на обработку
              информации о Пользователе, которую Исполнитель собирает, когда
              Пользователь использует Сервис. Если Вы не согласны с условиями
              Лицензионного соглашения – Вы вправе прекратить использование
              Сервиса.
            </p>

            <h3>2. Предмет Лицензионного соглашения</h3>

            <p>
              2.1. Исполнитель, являясь правообладателем исключительного права
              на Сервис, предоставляет Пользователю право использовать Сервис на
              условиях простой (неисключительной) лицензии в личных
              некоммерческих целях. Использование Сервиса иными способами, не
              предусмотренными настоящим Лицензионным соглашением, возможно
              только на основании отдельного соглашения с Исполнителем.
            </p>

            <p>
              2.2. Используя Сервис, Пользователь должен подтвердить, что он
              достиг возраста, который позволяет ему совершать действия в рамках
              Сервиса, которые он имеет право самостоятельно принимать и нести
              ответственность за них в соответствии с применимым
              законодательством. Если Пользователь не достиг такого возраста, он
              не имеет права использовать Сервис без согласия его родителей или
              законных представителей. Ответственность за действия Пользователя,
              не достигшего требуемого возраста, налагаются на его родителей или
              законных представителей.
            </p>

            <p>
              2.3. Сервис предоставляется Пользователю на следующих условиях:
            </p>
            <ul className={styles.popupList}>
              <li>
                2.3.1. Бесплатный доступ — первые сообщения в чат-боте
                предоставляются без взимания платы. По исчерпании бесплатных
                сообщений чат-бот направляет соответствующее уведомление.
              </li>
              <li>
                2.3.2. Платный доступ (подписка) — для продолжения использования
                после бесплатных сообщений необходимо оформить подписку с
                указанием стоимости и периодичности платежей.
              </li>
            </ul>

            <p>
              2.4. Ответы чат-бота, предоставляемые в Сервисе, носят
              развлекательный характер, не являются личной консультацией
              Исполнителя или инфлюенсера, и основываются на сведениях,
              полученных искусственным интеллектом в процессе его обучения.
            </p>

            <p>
              2.5. Исполнитель не несет ответственности за предоставляемые
              чат-ботом ответы и рекомендации, технические сбои, неисправности
              или перерывы в работе Сервиса, которые могут возникать по
              причинам, не зависящим от Исполнителя, включая форс-мажорные
              обстоятельства, сбои в работе серверов или интернет-провайдеров.
            </p>

            <p>
              2.6. Все права на интеллектуальную собственность, включая, но не
              ограничиваясь, контентом, кодом, алгоритмами и дизайном Сервиса,
              принадлежат Исполнителю. Пользователь не имеет права копировать,
              изменять, распространять или использовать Сервис для коммерческих
              целей без согласия Исполнителя.
            </p>

            <p>
              2.7. Исполнитель стремится совершенствовать использование
              Пользователями Сервиса, в том числе посредством внедрения нового
              функционала и контента. Внедрение таких изменений может
              осуществляться Исполнителем в любое время по своему усмотрению без
              дополнительного уведомления Пользователя.
            </p>

            <h3>3. Права и обязанности сторон</h3>

            <p>3.1. Исполнитель обязуется:</p>
            <ul className={styles.popupList}>
              <li>
                3.1.1. Обеспечить доступ Пользователя к Сервису в соответствии с
                условиями Соглашения.
              </li>
              <li>
                3.1.2. Обрабатывать данные Пользователя в соответствии с
                условиями Соглашения.
              </li>
            </ul>

            <p>3.2. Исполнитель вправе:</p>
            <ul className={styles.popupList}>
              <li>
                3.2.1. Ограничить доступ Пользователя к Сервису или
                заблокировать аккаунт Пользователя в случае нарушения условий
                ЛС, а также по собственному усмотрению без объяснения причин.
              </li>
            </ul>

            <p>3.3. Пользователь обязуется:</p>
            <ul className={styles.popupList}>
              <li>
                3.3.1. При использовании платной версии Сервиса оплачивать
                лицензию в соответствии с условиями Соглашения.
              </li>
              <li>
                3.3.2. Использовать Сервис исключительно в законных целях и
                только для личного использования.
              </li>
              <li>
                3.3.3. Не раскрывать доступ к своему аккаунту третьим лицам.
              </li>
            </ul>

            <h3>4. Условия подписки и оплаты</h3>

            <p>
              4.1. В рамках подписки Пользователю предоставляется простая
              (неисключительная) лицензия на условиях настоящего Соглашения, в
              течении срока, указанного в соответствующей подписке, на
              территории всего мира. Подписка на платную версию Сервиса
              продлевается автоматически, если автоматическое продление подписки
              не будет отключено Пользователем до окончания текущего периода.
              Лицензия считается предоставленной в момент оплаты.
            </p>

            <p>
              4.2. Оплата Сервиса осуществляется в соответствии с выбранной
              периодичностью и указанной стоимостью. Пользователь вправе не
              продлевать подписку, уведомив Исполнителя за 1 день до окончания
              текущего периода.
            </p>

            <p>
              4.3. Исполнитель оставляет за собой право закрыть доступ к Сервису
              в случае нарушения Пользователем условий использования Сервиса или
              нарушения действующего законодательства РФ, без возврата денежных
              средств.
            </p>

            <h3>
              5. Авторизация пользователей, обработка и использование данных.
            </h3>

            <p>
              5.1. Пользователь использует Сервис посредством учетной записи в
              сервисе Telegram. Все действия, выполняемые через учетную запись
              Пользователя, считаются выполненными Пользователем. Пользователь
              обязан обеспечить безопасность своей учетной записи и
              конфиденциальность его данных для авторизации. Пользователь дает
              согласие Исполнителю на обработку данных, оставленных
              Пользователем в сервисе Telegram. Исполнителем обрабатываются
              следующие данные Пользователя для целей Исполнения Соглашения с
              Пользователем по предоставлению функционала Сервиса и
              персонализации использования Сервиса, а также для показа рекламы,
              анализа и оптимизации рекламных кампаний: учетные данные
              Пользователя, оставленные при регистрации в сервисе Telegram, а
              именно: номер телефона, логин, фамилия, имя, сведения о себе, дата
              рождения; дополнительные данные, получаемые Исполнителем при
              доступе Пользователя к Сервису, включая события устройства,
              мобильную аналитику, IP-адрес хоста, вид операционной системы
              (устройства) Пользователя, географическое положение, данные о
              провайдере, uid устройства Пользователя, GAID/IDFA устройства
              Пользователя, и т.п.; запросы Пользователя, направленные в
              чат-бот.
            </p>

            <p>
              5.2. Пользователь соглашается с тем, что Исполнитель имеет право
              использовать обезличенные данные Пользователя для любых законных
              целей, включая аналитические цели, улучшение качества Сервиса,
              разработку нового функционала, а также в маркетинговых и рекламных
              целях.
            </p>

            <p>
              5.3. Пользователь соглашается получать от Сервиса
              рекламно-информационные, сервисные сообщения, в том числе
              сообщения о введении в действие новых либо отмене старых функций
              Сервиса, сообщения о прохождении опросов. Пользователь может
              отказаться от рекламных сообщений Сервиса в любое время через
              путем прекращения использования Сервиса.
            </p>

            <h3>6. Заключительные положения</h3>

            <p>
              6.1. Настоящее Лицензионное соглашение остается в силе до тех пор,
              пока одна из Сторон не откажется в одностороннем порядке от
              выполнения его условий. Пользователь вправе отказаться от
              настоящего Лицензионного соглашения в любое время, отписавшись от
              Чат-бота в сервисе Telegram, в этом случае Лицензионное соглашение
              считается расторгнутым. Исоплнитель вправе прекратить действие
              настоящего Лицензионного соглашения, уведомив Пользователя об этом
              с помощью любых средств, доступных для Исполнителя.
            </p>

            <p>
              6.2. Исполнитель вправе вносить изменения в настоящее Соглашение.
              Обновленная версия вступает в силу с момента её опубликования в
              приложении. Пользователь обязуется следить за изменениями в ЛС и
              согласен с тем, что продолжение использования Сервиса после
              изменения ЛС считается согласием с новыми условиями.
            </p>

            <p>
              6.3. Все споры, возникающие в связи с настоящим Лицензионным
              соглашением, должны быть разрешены Сторонами путём переговоров без
              обращения в суд. В случае, если Стороны не придут к согласию по
              урегулированию спора в досудебном порядке, споры разрешаются судом
              соответствующей юрисдикции по месту нахождения Исполнителя.
            </p>

            <p>
              6.4. Исполнитель не несет ответственности за содержимое и работу
              внешних сайтов или сервисов, на которые могут быть направлены
              сообщения и рекомендации чат-бота, а также за любые последствия,
              связанные с использованием ресурсов третьих лиц.
            </p>

            <p>
              6.5. В случае возникновения технических проблем или сбоя в работе
              Сервиса, Исполнитель обязуется уведомить Пользователя о возможных
              задержках или временных ограничениях.
            </p>
            <p>
              6.6. Настоящее Лицензионное соглашение регулируется
              законодательством Российской Федерации.
            </p>

            <h3>В редакции от 25.11.2024г.</h3>
          </div>
        </div>

        <div className={styles.popupFooter}>
          <button
            className={styles.popupButton}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              onClose();
            }}
          >
            {onlyRead ? 'Закрыть' : 'Подтвердить оферту'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OffertPopUp;

import React from 'react';

import { imageCacheService } from '../../services/preloadService';

import { cloudRemoveItem, localFlush } from '../../utils/cloudStorage';
import { cloudStorageKeys } from '../../utils/constants';

import Popup from '../PopUp/PopUp';

import styles from './ResetPopUp.module.css';
import { startConversation } from '../../api/conversation';

const ResetPopUp = ({ isOpen, onClose }) => {
  const handleKeepSubscription = () => {
    startConversation('/full_reset');
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    document.querySelector('.App').classList.add('fadeOut');
    imageCacheService.clearCache();
    localFlush();
    cloudRemoveItem(cloudStorageKeys.is_created);
    cloudRemoveItem(cloudStorageKeys.is_offer_confirmed);
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <h2 className={styles.title}>
        Вы уверены, что хотите сделать полный сброс?
      </h2>
      <p className={styles.description}>
        Это удалит кэш, очистит локальную историю и сделает полный сброс профиля
      </p>
      <div className={styles.buttons}>
        <button
          className={styles.keepButton}
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            onClose();
          }}
        >
          Отмена
        </button>
        <button
          className={styles.cancelButton}
          onClick={handleKeepSubscription}
        >
          Сделать сброс
        </button>
      </div>
    </Popup>
  );
};

export default ResetPopUp;

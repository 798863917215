import React, { useEffect, useState } from 'react';

import { startConversation } from '../../api/conversation';
import { createPayment, getPaymentStatus } from '../../api/payments';
import { getSubscriptionInfo } from '../../api/subscription';

import { sendGTMEvent } from '../../utils/analytics';
import {
  GA4_KEY,
  // URL_BOT
} from '../../utils/constants';
import { initializeWidget } from '../../utils/cloudpayments-init';

import SubscriptionMenuItem from '../SubscriptionMenuItem/SubscriptionMenuItem';
import SubscriptionLoader from '../SubscriptionLoader/SubscriptionLoader';
import Popup from '../PopUp/PopUp';
import CachedImage from '../CachedImage/CachedImage';

import styles from './SubscriptionMenu.module.css';

const subscriptionFeatures = [
  {
    title: 'Цифровая Клава на связи 24/7',
    description:
      'Всегда рядом, чтобы ответить на любые вопросы, поддержать и дать совет 💕',
    icon: '✨',
  },
  {
    title: 'Уникальные гайды и советы',
    description:
      'Собрала для тебя самые полезные материалы и лайфхаки от меня и проверенных экспертов 🎓',
    icon: '📚',
  },
  {
    title: 'Бьюти-диагностика',
    description: 'Определим твой тип кожи и волос 💇‍♀️',
    icon: '💆🏼‍♀️',
  },
  {
    title: 'Рекомендации продуктов',
    description:
      'Подберу для тебя только те средства, которые реально работают 🤝',
    icon: '🧴',
  },
  {
    title: 'Челленджи от Клавы',
    description:
      'Вместе внедрим уход в рутину! Буду поддабривать и подбадривать на каждом этапе 💪',
    icon: '💪🏼',
  },
  {
    title: 'Еженедельные Бьюти Таро',
    description:
      'Заглянем в будущее красоты и посмотрим, какие советы пригодятся на следующую неделю 🔮',
    icon: '🎴',
  },
  {
    title: 'Клава-рулетка',
    description:
      'каждый месяц случайным образом выбираю подписчика и записываю видео-ответ на вопрос! 💖',
    icon: '💖',
  },
  {
    title: 'И другие сюрпризы каждый месяц 🎁',
    description: '',
    icon: '🎁',
  },
];

const SubscriptionMenu = ({
  premium,
  price,
  widget,
  setSubscriptionInfo,
  setOpenInformation,
  subscriptionHash,
  setIsPaymentOpen,
}) => {
  const [hash, setHash] = useState(subscriptionHash || null);
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);

  useEffect(() => {
    if (hash) {
      setIsPaymentOpen(true);
      const handlePayment = async () => {
        try {
          const result = await initializeWidget(
            widget,
            price,
            hash,
            setIsLoading
          );
          if (result.success) {
            const status = await getPaymentStatus(hash);
            console.log('Статус платежа:', status);
            if (status.status === 'closed') {
              try {
                const subscription = await getSubscriptionInfo();
                setSubscriptionInfo(subscription);
                setOpenInformation(true);
              } catch (error) {
                console.error('Ошибка загрузки информации о подписке:', error);
                setOpenErrorPopup(true);
              }
            } else {
              setOpenErrorPopup(true);
            }
          }
        } catch (error) {
          console.error('Ошибка оплаты:', error);
          setOpenErrorPopup(true);
        } finally {
          setIsLoading(false);
          setIsPaymentOpen(false);
        }
      };

      handlePayment();
    }
  }, [
    hash,
    price,
    setSubscriptionInfo,
    setOpenInformation,
    widget,
    setIsPaymentOpen,
  ]);

  const handleClick = async () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');

    if (premium) {
      sendGTMEvent(GA4_KEY.manage_subscription);
      setOpenInformation(true);
    } else {
      try {
        sendGTMEvent(GA4_KEY.buy_subscription);

        // Получаем данные заказа с бэкенда
        const orderDetails = await createPayment('SUBSCRIPTION_WEEK');
        setHash(orderDetails.hash);
        console.log('Детали заказа:', orderDetails);

        // Открываем виджет оплаты
        // await handlePayment(orderDetails);
      } catch (error) {
        console.error('Ошибка при создании платежа:', error);
      }
    }
  };

  return (
    <>
      {isLoading && <SubscriptionLoader />}
      <div className={styles.subscriptionHeader}>
        <CachedImage
          src='assets/subscriptionLogo.png'
          className={styles.logo}
          alt='Бьюти Суета'
        />
      </div>

      <div className={styles.subscriptionContent}>
        <h2 className={styles.contentTitle}>Что входит в подписку</h2>

        <div className={styles.featuresList}>
          {subscriptionFeatures.map((feature) => (
            <SubscriptionMenuItem key={feature.title} feature={feature} />
          ))}
        </div>
      </div>

      <div className={styles.subscriptionFooter}>
        <button className={styles.subscribeButton} onClick={handleClick}>
          {premium
            ? 'Управление подпиской'
            : `Оформить подписку за ${price} ₽/месяц`}
        </button>
        <button
          className={styles.chatButton}
          onClick={() => {
            startConversation('Привет! Рассказажи мне про подписку!');
            document.querySelector('.App').classList.add('fadeOut');
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            sendGTMEvent(GA4_KEY.open_ai_chat);
            // window.Telegram.WebApp.openTelegramLink(URL_BOT);
          }}
        >
          Задать вопрос в чате
        </button>
      </div>
      <Popup isOpen={openErrorPopup} onClose={() => setOpenErrorPopup(false)}>
        <h2 className={styles.errorPopupTitle}>
          Оплата прошла неудачно
          <br />
          или вы закрыли окно оплаты
        </h2>
        <p className={styles.errorPopupDescription}>
          Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки
        </p>
        <div className={styles.errorPopupButtons}>
          <button
            className={styles.keepButton}
            onClick={() => {
              startConversation('Привет! У меня проблемы с оплатой!');
              document.querySelector('.App').classList.add('fadeOut');
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              setOpenErrorPopup(false);
              sendGTMEvent(GA4_KEY.open_ai_chat);
              // window.Telegram.WebApp.openTelegramLink(URL_BOT);
            }}
          >
            Обратиться в службу поддержки
          </button>
          <button
            className={styles.cancelButton}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              setOpenErrorPopup(false);
            }}
          >
            Закрыть
          </button>
        </div>
      </Popup>
    </>
  );
};

export default SubscriptionMenu;

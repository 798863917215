import React from 'react';
import { Link } from 'react-router-dom';

import { startConversation } from '../../api/conversation';

import { sendGTMEvent } from '../../utils/analytics';
import {
  GA4_KEY,
  // URL_BOT,
  routes,
} from '../../utils/constants';

import styles from './ProfileActions.module.css';

const ProfileActions = () => {
  const openAIChat = () => {
    // window.Telegram.WebApp.openTelegramLink(URL_BOT);
    startConversation('Привет!');
    document.querySelector('.App').classList.add('fadeOut');
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    sendGTMEvent(GA4_KEY.open_ai_chat);
    // window.Telegram.WebApp.sendData("chat");
  };

  return (
    <div className={styles.profileActionsContainer}>
      <div className={styles.profileActions}>
        <Link
          to={routes.communities}
          className={styles.profileButton}
          onClick={() => {
            sendGTMEvent(GA4_KEY.open_communities);
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
          }}
        >
          <i className='fas fa-comment-dollar'></i>Сообщества
        </Link>
        <button
          className={styles.profileButton}
          onClick={() => {
            openAIChat();
            // window.Telegram.WebApp.close();
          }}
        >
          <i className='fas fa-envelope'></i>Чат с Klava
        </button>
      </div>
      <Link
        to={routes.subscription}
        className={styles.profileButton}
        onClick={() => {
          sendGTMEvent(GA4_KEY.open_subscription);
          window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
        }}
      >
        Подписки
      </Link>
    </div>
  );
};

export default ProfileActions;

import makeRequest from './apiConfig';

const socialEndpoints = {
  setLike: '/api/social/like',
  getPostDetails: '/api/social/post',
  setComment: '/api/social/comment',
  sharePost: '/api/social/share',
};

export const setLike = async (post_id) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: socialEndpoints.setLike,
      body: JSON.stringify({
        post_id: post_id,
      }),
    });
    console.log('like');
    console.log(response);
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const setComment = async (body) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: socialEndpoints.setComment,
      body: JSON.stringify(body),
    });
    console.log(response);
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const getPostDetails = async (post_id) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: socialEndpoints.getPostDetails,
      params: post_id ? { post_id: post_id } : {},
    });
    return response;
  } catch (error) {
    if (error.status === 404) {
      console.log('Post not found:', error);
    }
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const sharePost = async (post_id) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: socialEndpoints.sharePost,
      body: JSON.stringify({
        post_id: post_id,
      }),
    });
    console.log(response);
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

import React, { useEffect, useState } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ThumbnailIcon,
} from '@react-pdf-viewer/default-layout';
import { SearchIcon } from '@react-pdf-viewer/search';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

import SearchSidebar from './SearchSidebar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import styles from './PDFViewer.module.css';
import './styles.css';

const PDFViewer = ({ pdfUrl, onClose }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    const sidebarElement = document.querySelector(
      '.rpv-default-layout__sidebar--ltr'
    );
    if (sidebarElement) {
      sidebarElement.style.display = showSidebar ? 'block' : 'none';
    }
  }, [showSidebar]);

  // Initialize thumbnail plugin instance
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  // Create default layout plugin instance with thumbnails configuration
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(props) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ZoomIn,
            ZoomOut,
          } = props;

          return (
            <div className={styles.toolbarContent}>
              <div>
                <button
                  onClick={() => setShowSidebar(!showSidebar)}
                  className={styles.button}
                >
                  <svg
                    aria-hidden='true'
                    className='rpv-core__icon'
                    focusable='false'
                    height='16px'
                    viewBox='0 0 24 24'
                    width='16px'
                    strokeWidth='1'
                  >
                    <path
                      d='M4 6h16M4 12h16M4 18h16'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                    />
                  </svg>
                </button>
              </div>
              <div className={styles.pagination}>
                <ZoomOut />
                <GoToPreviousPage />
                <CurrentPageInput /> / <NumberOfPages />
                <GoToNextPage />
                <ZoomIn />
              </div>
              <div>
                <button onClick={onClose} className={styles.button}>
                  ✕
                </button>
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    sidebarTabs: () => {
      return [
        {
          content: (
            <SearchSidebar
              searchPluginInstance={
                defaultLayoutPluginInstance.toolbarPluginInstance
                  .searchPluginInstance
              }
            />
          ),
          icon: <SearchIcon />,
          title: 'Search',
        },
        {
          content: <Thumbnails />,
          icon: <ThumbnailIcon />,
          title: 'Thumbnails',
        },
      ];
    },
  });

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();

    window.Telegram.WebApp.BackButton.onClick(() => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      window.Telegram.WebApp.setHeaderColor('#fff0f5');
      onClose();
      window.Telegram.WebApp.BackButton.hide();
    });

    return () => {
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [onClose]);

  return (
    <div className={styles.overlay}>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
        <div className={styles.viewerContainer}>
          <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance, thumbnailPluginInstance]}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PDFViewer;

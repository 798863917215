import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import SubscriptionInformation from '../../components/SubscriptionInformation/SubscriptionInformation';
import SubscriptionMenu from '../../components/SubscriptionMenu/SubscriptionMenu';

import styles from './Subscription.module.css';

const Subscription = ({
  widget,
  subscriptionInfo,
  setSubscriptionInfo,
  openImformation,
  setOpenInformation,
  subscriptionHash,
  setSubscriptionHash,
}) => {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isShowPaymentsHistory, setIsShowPaymentsHistory] = useState(false);

  const {
    history,
    price,
    user_data: {
      notify_subscription,
      notify_updates,
      premium,
      subscription_expires_at,
    },
  } = subscriptionInfo;

  console.log(isPaymentOpen);

  useEffect(() => {
    sendGTMEvent(GA4_KEY.navigate(window.location.href));

    const handleBackButton = () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      if (isPaymentOpen) {
        widget.close();
        return;
      }

      if (isShowPaymentsHistory) {
        setIsShowPaymentsHistory(false);
        return;
      }

      if (isDrawerOpen) {
        setIsDrawerOpen(false);
        return;
      }

      if (openImformation && !isDrawerOpen) {
        setOpenInformation(false);
        return;
      }

      navigate(-1);
    };

    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.setHeaderColor(
      isPaymentOpen
        ? '#222D42'
        : isShowPaymentsHistory
        ? '#fff'
        : isDrawerOpen
        ? '#7F7F7F'
        : '#fff'
    );
    window.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      setSubscriptionHash(null);
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };
  }, [
    openImformation,
    isDrawerOpen,
    navigate,
    setOpenInformation,
    setSubscriptionInfo,
    setSubscriptionHash,
    widget,
    isPaymentOpen,
    isShowPaymentsHistory, // Added this dependency
  ]);

  return (
    <div className={styles.container}>
      {openImformation ? (
        <SubscriptionInformation
          premium={premium}
          nextPaymentDate={subscription_expires_at}
          price={price}
          subscriptionNotify={notify_subscription}
          contentNotify={notify_updates}
          history={history}
          setSubscriptionInfo={setSubscriptionInfo}
          setOpenInformation={setOpenInformation}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          setIsShowPaymentsHistory={setIsShowPaymentsHistory}
          isShowPaymentsHistory={isShowPaymentsHistory}
        />
      ) : (
        <SubscriptionMenu
          premium={premium}
          price={price}
          widget={widget}
          setSubscriptionInfo={setSubscriptionInfo}
          setOpenInformation={setOpenInformation}
          subscriptionHash={subscriptionHash}
          setIsPaymentOpen={setIsPaymentOpen}
        />
      )}
    </div>
  );
};

export default Subscription;

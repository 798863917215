import React, { useState, useEffect } from 'react';

import styles from './PopUp.module.css';

const Popup = ({
  isOpen,
  onClose,
  children,
  width = 'calc(100% - 80px)',
  maxWidth = '400px',
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      setIsClosing(false);
    } else if (shouldRender) {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen, shouldRender]);

  const handleClose = () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    setIsClosing(true);
    const timer = setTimeout(onClose, 300);
    return () => clearTimeout(timer);
  };

  if (!shouldRender) return null;

  return (
    <>
      <div
        className={`${styles.overlay} ${isClosing ? styles.fadeOut : ''}`}
        onClick={handleClose}
      />
      <div
        className={`${styles.modal} ${isClosing ? styles.scaleDown : ''}`}
        style={{ width, maxWidth }}
      >
        {children}
      </div>
    </>
  );
};

export default Popup;

import ReactGA from 'react-ga4';

import { DEFAULT_USER } from './constants';

const user_id =
  window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || DEFAULT_USER.id;

export const sendGTMEvent = (event) => {
  ReactGA.event({
    category: event.category,
    action: event.action,
    label: user_id,
    customParameters: {
      userId: user_id,
    },
  });
};

import makeRequest from './apiConfig';

const subscriptionEndpoints = {
  getSubscriptionInfo: '/api/subscription',
  updatePermissions: '/api/update-user-permissions',
};

export const getSubscriptionInfo = async () => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: subscriptionEndpoints.getSubscriptionInfo,
    });
    return response;
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const updatePermissions = async (body) => {
  try {
    const response = await makeRequest({
      method: 'PUT',
      endpoint: subscriptionEndpoints.updatePermissions,
      body: JSON.stringify(body),
    });
    return response;
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export async function cloudSetItem(key, value) {
  return new Promise((resolve, reject) => {
    if (
      !window.Telegram ||
      !window.Telegram.WebApp ||
      !window.Telegram.WebApp.CloudStorage
    ) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    const valueString = JSON.stringify(value);

    window.Telegram.WebApp.CloudStorage.setItem(key, valueString, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
}

export async function cloudGetItem(key) {
  return new Promise((resolve, reject) => {
    if (
      !window.Telegram ||
      !window.Telegram.WebApp ||
      !window.Telegram.WebApp.CloudStorage
    ) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    window.Telegram.WebApp.CloudStorage.getItem(key, (error, value) => {
      if (error) {
        reject(error);
      } else {
        try {
          const parsedValue = value ? JSON.parse(value) : null;
          resolve(parsedValue);
        } catch (parseError) {
          reject(new Error('Ошибка при парсинге данных из хранилища'));
        }
      }
    });
  });
}

export async function cloudRemoveItem(key) {
  return new Promise((resolve, reject) => {
    window.Telegram.WebApp.CloudStorage.removeItem(key, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
}

export async function cloudGetKeys() {
  return new Promise((resolve, reject) => {
    window.Telegram.WebApp.CloudStorage.getKeys((error, keys) => {
      if (error) {
        reject(error);
      } else {
        resolve(keys);
      }
    });
  });
}

export function localGetItem(key, callback = null) {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  const now = Date.now();
  if (item.expiry && now > item.expiry) {
    if (callback) {
      callback(item.value);
    }
    localRemoveItem(key);
    return null;
  }
  return item.value;
}

export function localSetItem(key, value, expireInSeconds = null) {
  const now = Date.now();
  const item = {
    value,
    expiry: expireInSeconds ? now + expireInSeconds * 1000 : null,
  };
  const itemString = JSON.stringify(item);
  localStorage.setItem(key, itemString);
}

export function localRemoveItem(key) {
  localStorage.removeItem(key);
}

export function localGetKeys() {
  return Object.keys(localStorage);
}

export function localFlush() {
  localStorage.clear();
}

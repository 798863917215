import React, { useState } from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import CachedImage from '../CachedImage/CachedImage';

import styles from './StoriesItem.module.css';
const StoriesItem = ({ story, openStory }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className={styles.story}
      onClick={() => {
        sendGTMEvent(GA4_KEY.open_story(story.title));
        window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
        openStory(story);
      }}
    >
      <div className={styles.storyCircle}>
        {!imageLoaded && (
          <div className={styles.storiesLoader}>
            <div className={styles.loader}></div>
          </div>
        )}
        <CachedImage
          src={`${story.image}`}
          alt={story.title}
          className={styles.storyImage}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <span className={styles.storyText}>{story.title}</span>
    </div>
  );
};

export default StoriesItem;

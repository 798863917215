import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVICE_URL;

async function makeRequest({
  method = 'GET',
  endpoint,
  body = null,
  params = null,
}) {
  try {
    const url = `${BASE_URL}${endpoint}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const token = JSON.parse(localStorage.getItem('Token'));
    if (token) {
      headers['Authorization'] = token.value;
    }

    const config = {
      method,
      url,
      headers,
      data: body,
      params,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Request error:', error.message);
    throw error;
  }
}

export default makeRequest;

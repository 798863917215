import React, { useState } from 'react';

import { formatDate } from '../../utils/common';

import ResetPopUp from '../ResetPopUp/ResetPopUp';

import styles from './PaymentHistory.module.css';

const PaymentHistory = ({ history = [] }) => {
  const [isOpenReset, setIsOpenReset] = useState(false);

  const closedPayments = history.filter(
    (payment) => payment.status === 'closed'
  );

  return (
    <div className={styles.paymentHistoryContainer}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          История
          <span
            onClick={() => {
              setIsOpenReset(true);
            }}
          >
            {' '}
          </span>
          платежей
        </h1>
        <div className={styles.paymentList}>
          {closedPayments.length === 0 ? (
            <p className={styles.noPayments}>История платежей пуста</p>
          ) : (
            closedPayments.map((payment, index) => (
              <div key={index} className={styles.paymentItem}>
                <span className={styles.date}>
                  {formatDate(payment.timestamp)}
                </span>
                <div className={styles.amountAndCheck}>
                  <span className={styles.amount}>{payment.amount} ₽</span>
                  <i
                    className='fa-solid fa-circle-check'
                    style={{ color: '#22c55e' }}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <ResetPopUp isOpen={isOpenReset} onClose={() => setIsOpenReset(false)} />
    </div>
  );
};

export default PaymentHistory;

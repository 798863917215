import React, { useState } from 'react';

import styles from './SubscriptionMenuItem.module.css';

const SubscriptionMenuItem = ({ feature }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.featureItem}>
      <div className={styles.featureCheck}>
        <span>{feature.icon}</span>
      </div>
      <div className={styles.featureContent}>
        <h3 className={styles.featureTitle} onClick={() => setOpen(!open)}>
          {feature.title}
          {feature?.description && (
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className={`${styles.arrow} ${open ? styles.expanded : ''}`}
            >
              <path
                d='M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z'
                fill='#7F7F7F'
              />
            </svg>
          )}
        </h3>
        <div
          className={`${styles.featureDescription} ${
            open ? styles.expanded : ''
          }`}
        >
          {feature.description}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionMenuItem;

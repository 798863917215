export const cloudStorageKeys = {
  is_offer_confirmed: 'is_offer_confirmed',
  is_created: 'is_created',
};

export const APP_URL = window.location.origin;
export const MINI_APP_URL = 'https://t.me/KlavagolmanBot/halo';
export const URL_CHAT = 'https://t.me/klavagolman';
export const URL_BOT = 'https://t.me/klavagolmanbot';

export const routes = {
  home: '/',
  communities: '/communities',
  product: (id = ':id') => `/products/${id}`,
  subscription: '/subscription',
};


export const DEFAULT_USER = {
  id: 252,
  first_name: '123',
  last_name: '345',
  username: 'asdw',
  language_code: 'ru',
  allows_write_to_pm: true,
  is_premium: true,
  photo_url: 'hey.com',
};

export const GA4_KEY = {
  loading: {
    category: 'LOADING',
    action: 'loading_app',
  },
  open_offert_popup: {
    category: 'FIRST_ENTERING_TO_APP',
    action: 'open_offert_popup/first_entering_to_app',
  },
  confirm_offert: {
    category: 'CLICK_BUTTON',
    action: 'user_confirm_offert/button_"Подтвердить_оферту"',
  },
  open_telegtam_channel: {
    category: 'CLICK_AVATAR',
    action: 'open_"t.me/klavagolman"',
  },
  open_social_media: (name) => {
    return {
      category: 'CLICK_SOCIAL_MEDIA_ICON',
      action: `open_"${name}"`,
    };
  },
  open_ai_chat: {
    category: 'CLICK_BUTTON',
    action: 'open_ai_chat/button_"Чат_с_Klava_Golman"',
  },
  open_communities: {
    category: 'CLICK_BUTTON',
    action: 'open_communities/button_"Сообщества"',
  },
  open_subscription: {
    category: 'CLICK_BUTTON',
    action: 'open_subscription/button_"Подписка"',
  },
  navigate: (route) => {
    return {
      category: 'NAVIGATE',
      action: `navigate_"${route}"`,
    };
  },
  open_story_product: (link) => {
    return {
      category: 'CLICK_BUY_BUTTON_STORY_PRODUCT',
      action: `open_story_product_"${link}"`,
    };
  },
  open_channel: (name, price) => {
    return {
      category: 'CLICK_CHANNEL_BUTTON',
      action: `open_channel_"${name}"button_"${
        price === 'FREE' ? 'Join Now' : 'Buy'
      }"`,
    };
  },
  open_story: (name) => {
    return {
      category: 'CLICK_HISTORY_ICON',
      action: `open_story_"${name}"`,
    };
  },
  open_guide: (name) => {
    return {
      category: 'CLICK_OPEN_GUIDE_BUTTON',
      action: `open_guide_"${name}"`,
    };
  },
  buy_product: (link, price, cta) => {
    return {
      category: 'CLICK_BUY_PRODUCT_BUTTON',
      action: `"${link}"_for_"${price}"/button_"${cta}"`,
    };
  },
  choose_category: (name) => {
    return {
      category: 'CLICK_TO_CATEGORY',
      action: `selected_category_"${name}"`,
    };
  },
  open_product: (name, category) => {
    return {
      category: 'CLICK_TO_PRODUCT',
      action: `selected_product_"${name}"/ in_category_"${category}"`,
    };
  },
  set_like: (post_id, isLike) => {
    return {
      category: 'CLICK_TO_LIKE',
      action: `${isLike ? 'like' : 'unlike'}_post_"${post_id}"/`,
    };
  },
  show_comments: (post_id) => {
    return {
      category: 'CLICK_TO_COMMENTS_ICON',
      action: `show_comments_"${post_id}"/click_comments_icon`,
    };
  },
  share_post: (post_id) => {
    return {
      category: 'SHARE_POST',
      action: `share_post_"${post_id}"/click_share_icon`,
    };
  },
  send_comment: (post_id, user_id) => {
    return {
      category: 'send_comment',
      action: `send_comment_for_"${post_id}"_user_"${user_id}"/click_send_button`,
    };
  },
  manage_subscription: {
    category: 'OPEN_MANAGE_SUBSCRIPTION',
    action: 'open_manage_subscription/click_"управление подпиской"',
  },
  manage_subscription_drawer: {
    category: 'OPEN_MANAGE_SUBSCRIPTION_DRAWER',
    action:
      'open_manage_subscription_drawer/click_"управление подпиской"_in_information',
  },
  buy_subscription: {
    category: 'BUY_SUBSCRIPTION_',
    action: 'buy_subscription/click_"оформить подписку"',
  },
  switch_notification: (isActive) => {
    return {
      category: 'SWITCH_NOTIFICATION',
      action: `activate_notification_${
        isActive ? 'on' : 'off'
      }/click_switcher_for_notification`,
    };
  },
  switch_features_and_content: (isActive) => {
    return {
      category: 'SWITCH_FEATURES_AND_CONTENT',
      action: `activate_features_and_content_${
        isActive ? 'on' : 'off'
      }/click_switcher_for_features_and_content`,
    };
  },
  open_payments_history: {
    category: 'OPEN_PAYMENTS_HISTORY',
    action: 'open_payments_history/click_"история платежей"',
  },
  cancel_subscription_modal: {
    category: 'OPEN_CANCEL_SUBSCRIPTION_MODAL',
    action: 'open_cancel_subscription_modal/click_"отменить подписку"',
  },
  change_payment_method: {
    category: 'CHANGE_PAYMENT_METHOD',
    action: 'change_payment_method/click_"изменить метод оплаты"',
  },
  leave_subscription: {
    category: 'LEAVE_SUBSCRIPTION',
    action: 'leave_subscription/click_"оставить подписку"',
  },
  cancel_subscription: {
    category: 'CANCEL_SUBSCRIPTION',
    action: 'cancel_subscription/click_"отменить подписку"',
  },
};

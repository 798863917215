// components/common/CachedImage.jsx
import React, { useEffect, useState } from 'react';
import { imageCacheService } from '../../services/preloadService';

const CachedImage = ({ src, alt, className, ...props }) => {
  console.log('render CachedImage:', src);

  const isExternalUrl = (url) =>
    url.startsWith('http://') || url.startsWith('https://');

  const [imageSrc, setImageSrc] = useState(() => {
    if (isExternalUrl(src)) {
      return src;
    }
    return imageCacheService.getLocalCachedUrl(src) || src;
  });

  useEffect(() => {
    let isMounted = true;

    const loadImage = async () => {
      if (isExternalUrl(src)) {
        setImageSrc(src);
        return;
      }

      // Проверяем, нужно ли загружать изображение
      if (!imageCacheService.needsLoading(src)) {
        const cachedUrl = imageCacheService.getLocalCachedUrl(src);
        if (cachedUrl && isMounted) {
          setImageSrc(cachedUrl);
        }
        return;
      }

      try {
        const cachedUrl = await imageCacheService.getCachedImage(src);
        if (isMounted) {
          setImageSrc(cachedUrl);
        }
      } catch (error) {
        console.warn(`Failed to load cached image ${src}:`, error);
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [src]);

  return <img src={imageSrc} alt={alt} className={className} {...props} />;
};

export default CachedImage;

import React, { useEffect } from 'react';

import ContentToggle from '../../components/ContentToggle/ContentToggle';
import ProductList from '../../components/ProductList/ProductList';
import ProfileActions from '../../components/ProfileActions/ProfileActions';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import StoriesContainer from '../../components/StoriesContainer/StoriesContainer';
// import SubscriptionPopUp from '../../components/SubscriptionPopUp';

import '../../App.css';

function Home({
  productsCatalog,
  productsDetails,
  setProductsDetails,
  selectedProduct,
  setSelectedProduct,
  productId,
  setProductId,
  activeCategory,
  setActiveCategory,
}) {
  // const [isOfferWallOpen, setIsOfferWallOpen] = useState(false);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.hide();
    window.Telegram.WebApp.setHeaderColor('#fff0f5');
  }, []);

  return (
    <div className='container'>
      <ProfileHeader />
      <ProfileActions />
      <StoriesContainer />
      <ContentToggle
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <ProductList
        activeCategory={activeCategory}
        productsCatalog={productsCatalog}
        productsDetails={productsDetails}
        setProductsDetails={setProductsDetails}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        productId={productId}
        setProductId={setProductId}
      />
      {/* <SubscriptionPopUp
        isOpen={isOfferWallOpen}
        onClose={() => setIsOfferWallOpen(false)}
        onStartChat={() => {
          console.log('Starting chat...');
          setIsOfferWallOpen(false);
        }}
      /> */}
    </div>
  );
}

export default Home;

import React, { useEffect, useRef, useState } from 'react';

import { setComment } from '../../api/social';

import { DEFAULT_USER, GA4_KEY } from '../../utils/constants';
import { sendGTMEvent } from '../../utils/analytics';

import styles from './CommentForm.module.css';

const CommentForm = ({ postId, setProductsDetails, onClose }) => {
  const [newComment, setNewComment] = useState('');
  const maxLength = 500;
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newComment.trim()) return;

    const telegram = window.Telegram.WebApp;
    const userData = telegram?.initDataUnsafe?.user || DEFAULT_USER;
    const productDetailComment = {
      content: newComment,
      created_at: new Date().toISOString(),
      user_id: userData.id,
      username: userData.username,
    };

    try {
      setProductsDetails((prev) => {
        if (!Array.isArray(prev)) return prev;

        const productExists = prev.some(
          (product) => +product.post_id === +postId
        );

        if (productExists) {
          return prev.map((product) => {
            if (+product.post_id === +postId) {
              return {
                ...product,
                comments: [...(product.comments || []), productDetailComment],
              };
            }
            return product;
          });
        }

        return [
          ...prev,
          {
            comments: [productDetailComment],
            is_liked: false,
            is_shared: false,
            likes: 0,
            post_id: postId,
            shares: 0,
          },
        ];
      });
      setNewComment('');
      onClose();
      setComment({
        post_id: postId,
        comment: newComment,
      });
    } catch (error) {
      console.error('Failed to add comment:', error);
    } finally {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      sendGTMEvent(GA4_KEY.send_comment(postId, userData.id));
    }
  };

  return (
    <div className={styles.overlay}>
      <div ref={modalRef} className={styles.modal}>
        <div className={styles.header}>
          <h3>Новый комментарий</h3>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} className={styles.form}>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value.slice(0, maxLength))}
            placeholder='Оставьте ваш комментарий...'
            className={styles.textarea}
            maxLength={maxLength}
          />
          <div className={styles.footer}>
            <span className={styles.counter}>
              {newComment.length}/{maxLength}
            </span>
            <button
              type='submit'
              className={styles.submitButton}
              disabled={!newComment.trim()}
            >
              Отправить
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentForm;

import React, { useState, useEffect } from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import styles from './SubscriptionDrawer.module.css';

const SubscriptionDrawer = ({
  isOpen,
  setIsDrawerOpen,
  setIsCancelModalOpen,
  setIsShowPaymentsHistory,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      setIsClosing(false);
    } else if (shouldRender) {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen, shouldRender]);

  const handleClose = () => {
    setIsClosing(true);
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    window.Telegram.WebApp.setHeaderColor('#fff');
    const timer = setTimeout(() => setIsDrawerOpen(false), 300);
    return () => clearTimeout(timer);
  };

  if (!shouldRender) return null;

  return (
    <>
      <div
        className={`${styles.overlay} ${isClosing ? styles.fadeOut : ''}`}
        onClick={handleClose}
      />
      <div className={`${styles.drawer} ${isClosing ? styles.slideDown : ''}`}>
        <div className={styles.content}>
          <div className={styles.slider} />
          <h2 className={styles.title}>Управление подпиской</h2>

          <div
            className={styles.menuItem}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              setIsShowPaymentsHistory(true);
              sendGTMEvent(GA4_KEY.open_payments_history);
            }}
          >
            <span>История платежей</span>
            <span className={styles.arrow}>→</span>
          </div>

          <div
            className={styles.menuItem}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              sendGTMEvent(GA4_KEY.change_payment_method);
            }}
          >
            <span>Изменить способ оплаты</span>
            <span className={styles.arrow}>→</span>
          </div>

          <div
            className={styles.menuItem}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              setIsDrawerOpen(false);
              setIsCancelModalOpen(true);
              sendGTMEvent(GA4_KEY.cancel_subscription_modal);
            }}
          >
            <span className={styles.cancelText}>Отменить подписку</span>
            <span className={styles.arrow}>→</span>
          </div>

          <button className={styles.backButton} onClick={handleClose}>
            Назад
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDrawer;

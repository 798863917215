import React from 'react';
import styles from './SubscriptionLoader.module.css';

const SubscriptionLoader = () => {
  return (
    <div className={styles.overlay}>
      <div className={styles.loader}>
        <svg className={styles.circular} viewBox='0 0 100 100'>
          <circle
            className={styles.staticCircle}
            cx='50'
            cy='50'
            r='40'
            fill='none'
            strokeWidth='7'
          />
          <circle
            className={styles.path}
            cx='50'
            cy='50'
            r='40'
            fill='none'
            strokeWidth='7'
          />
        </svg>
      </div>
    </div>
  );
};

export default SubscriptionLoader;

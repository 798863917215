import React from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import Popup from '../PopUp/PopUp';

import styles from './CancelSubscriptionModal.module.css';

const CancelSubscriptionModal = ({
  isOpen,
  onClose,
  onConfirm,
  nextPaymentDate,
}) => {
  const handleKeepSubscription = () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    sendGTMEvent(GA4_KEY.leave_subscription);
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        sendGTMEvent(GA4_KEY.leave_subscription);
        onClose();
      }}
    >
      <h2 className={styles.title}>
        Вы уверены, что хотите отменить подписку?
      </h2>
      <p className={styles.description}>
        Мы отменим автосписание, но доступ к материалам останется до окончания
        подписки {nextPaymentDate}
      </p>
      <div className={styles.buttons}>
        <button className={styles.keepButton} onClick={handleKeepSubscription}>
          Оставить подписку
        </button>
        <button
          className={styles.cancelButton}
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            onConfirm();
          }}
        >
          Отменить подписку
        </button>
      </div>
    </Popup>
  );
};

export default CancelSubscriptionModal;

import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { setLike, sharePost } from '../../api/social.js';
import { startConversation } from '../../api/conversation.js';

import { profileData } from '../../Config.js';
import { sendGTMEvent } from '../../utils/analytics.js';
import {
  APP_URL,
  DEFAULT_USER,
  GA4_KEY,
  MINI_APP_URL,
  // URL_BOT,
  routes,
} from '../../utils/constants.js';

import CommentForm from '../../components/CommentForm/CommentForm.jsx';
import Comments from '../../components/Comments/Comments.jsx';
import PDFViewer from '../../components/PDFViewer/PDFViewer.jsx';
import CachedImage from '../../components/CachedImage/CachedImage.jsx';

import styles from './Product.module.css';

const Product = ({
  product,
  productDetails,
  setProductsDetails,
  isHaveSubscriptions,
}) => {
  const navigate = useNavigate();

  const sliderRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const userInfo =
    window?.Telegram?.WebApp?.initDataUnsafe?.user || DEFAULT_USER;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [sharesCount, setSharesCount] = useState(0);
  const [showFullScreenPDF, setShowFullScreenPDF] = useState(false);

  const isShowSlider =
    Array.isArray(product.images) && product.images.length > 1;

  useEffect(() => {
    if (productDetails) {
      setIsLiked(productDetails.is_liked || false);
      setLikesCount(productDetails.likes || 0);
      setSharesCount(productDetails.shares || 0);
    }
  }, [productDetails]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram?.WebApp?.setHeaderColor('#fff');
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(routes.home);
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    });

    return () => {
      window.Telegram.WebApp.setHeaderColor('#fff0f5');
      window.Telegram.WebApp.BackButton.onClick = () => {};
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [navigate]);

  useEffect(() => {
    sendGTMEvent(GA4_KEY.navigate(window.location.href));
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'transform 0.3s ease-in-out';
      sliderRef.current.style.transform = `translateX(-${
        (currentImageIndex + 1) * 100
      }%)`;
    }
  }, [currentImageIndex]);

  const {
    id,
    title,
    images,
    description,
    price,
    type,
    category,
    hair_concerns,
    hair_concerns_description,
    usage_instructions,
    usage_step,
  } = product;

  const likedBy = profileData.likedBy;

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleBuy = () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    if (type && type === 'guide') {
      if (isHaveSubscriptions) {
        setShowFullScreenPDF(true);
        sendGTMEvent(GA4_KEY.open_guide(product.title));
      } else {
        navigate(routes.subscription);
        sendGTMEvent(GA4_KEY.open_guide(product.title));
      }
    } else {
      sendGTMEvent(GA4_KEY.buy_product(title, price, `Заказать ${price}`));
      alert(`Thank you for purchasing ${title} for ${price}`);
    }
  };

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
    isDraggingRef.current = true;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    const currentX = e.touches[0].clientX;
    const diff = startXRef.current - currentX;
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        handleNextImage();
      } else {
        handlePrevImage();
      }
      isDraggingRef.current = false;
    }
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
  };

  const handleSetLike = async (id) => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred(
      isLiked ? 'light' : 'medium'
    );

    const newIsLiked = !isLiked;
    const newLikesCount = isLiked ? likesCount - 1 : likesCount + 1;

    setProductsDetails((prevDetails) => {
      if (!Array.isArray(prevDetails)) return prevDetails;

      const productExists = prevDetails.some(
        (product) => +product.post_id === +id
      );

      if (productExists) {
        return prevDetails.map((product) => {
          if (+product.post_id === +id) {
            return {
              ...product,
              is_liked: newIsLiked,
              likes: newLikesCount,
            };
          }
          return product;
        });
      }

      return [
        ...prevDetails,
        {
          comments: [],
          is_liked: newIsLiked,
          is_shared: false,
          likes: newLikesCount,
          post_id: id,
          shares: 0,
        },
      ];
    });
    try {
      // Отправляем запрос на сервер
      await setLike(id);
    } catch (error) {
      console.error('Error occurred while setting like:', error);
    } finally {
      sendGTMEvent(GA4_KEY.set_like(id, !isLiked));
    }
  };

  const handleCommentClick = () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    setShowComments(!showComments);
    sendGTMEvent(GA4_KEY.show_comments(id));
  };

  const handleShare = async () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

    const newSharesCount = sharesCount + 1;
    setProductsDetails((prevDetails) => {
      if (!Array.isArray(prevDetails)) return prevDetails;

      const productExists = prevDetails.some(
        (product) => +product.post_id === +id
      );

      if (productExists) {
        return prevDetails.map((product) => {
          if (+product.post_id === +id) {
            return {
              ...product,
              is_shared: true,
              shares: newSharesCount,
            };
          }
          return product;
        });
      }

      return [
        ...prevDetails,
        {
          comments: [],
          is_liked: isLiked,
          is_shared: true,
          likes: likesCount,
          post_id: id,
          shares: newSharesCount,
        },
      ];
    });

    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      `${MINI_APP_URL}?startapp=products_${id}`
    )}&parse_mode=Markdown&text=${encodeURIComponent(
      `'${title}' от Клавы Голман`
    )}`;
    window.open(shareUrl, '_blank');

    try {
      await sharePost(id);
    } catch (error) {
      console.error('Error occurred while sharing post:', error);
    } finally {
      sendGTMEvent(GA4_KEY.share_post(id));
    }
  };

  return (
    <div id='product-modal' className={styles['product-modal']}>
      {showFullScreenPDF ? (
        <PDFViewer
          pdfUrl={`${APP_URL}/${product.pdfFile}`}
          onClose={() => setShowFullScreenPDF(false)}
        />
      ) : (
        <div className={styles['product-modal-content']}>
          <div className={styles['modal-header']}>
            <h2 id='product-title' className={styles['product-modal-title']}>
              {title}
            </h2>
            <Link to={routes.home} className={styles['product-modal-close']}>
              &times;
            </Link>
          </div>
          {isShowSlider ? (
            <>
              <div className={styles['product-slider']}>
                <div
                  className={styles['product-slider-container']}
                  ref={sliderRef}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <CachedImage
                    src={`${images[images.length - 1]}`}
                    alt='Last'
                    className={styles['product-slider-image']}
                  />
                  {images.map((image, index) => (
                    <CachedImage
                      key={index}
                      id={index === 0 ? 'product-image' : undefined}
                      src={`${image}`}
                      alt={`${title} - ${index + 1}`}
                      className={styles['product-slider-image']}
                    />
                  ))}
                  <CachedImage
                    src={`${images[0]}`}
                    alt='First'
                    className={styles['product-slider-image']}
                  />
                </div>
                <button
                  className={`${styles['slider-nav']} ${styles['prev']}`}
                  onClick={handlePrevImage}
                >
                  &lt;
                </button>
                <button
                  className={`${styles['slider-nav']} ${styles['next']}`}
                  onClick={handleNextImage}
                >
                  &gt;
                </button>
              </div>
              {images.length > 1 && (
                <div className={styles['slider-dots']}>
                  {images.map((_, index) => (
                    <div
                      key={index}
                      className={`${styles['slider-dot']} ${
                        currentImageIndex === index ? styles['active'] : ''
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <CachedImage
              src={images ? `${images}` : `assets/why_beauty.JPG`}
              alt={title}
              className={styles['product-image']}
            />
          )}
          <div
            className={`${styles['product-info']} ${
              showComments ? styles['product-comments-info'] : ''
            }`}
          >
            <div className={styles['product-stats']}>
              <div
                className={styles['stat-item']}
                onClick={() => handleSetLike(id)}
              >
                <i
                  className={`fas fa-heart ${styles['stat-icon']} ${
                    isLiked ? styles['liked'] : ''
                  }`}
                  id='like-button'
                ></i>
                <span id='product-likes'>{likesCount}</span>
              </div>
              <div className={styles['stat-item']} onClick={handleCommentClick}>
                <i
                  className={`fas fa-comment ${styles['stat-icon']}`}
                  id='comment-button'
                ></i>
                <span id='product-reviews'>
                  {productDetails?.comments?.length || 0}
                </span>
              </div>
              <div className={styles['stat-item']} onClick={handleShare}>
                <i
                  className={`fas fa-paper-plane ${styles['stat-icon']}`}
                  id='share-button'
                ></i>
                <span id='product-shares'>{productDetails?.shares || 0}</span>
              </div>
              {likesCount > 0 && (
                <div className={styles['also-liked']}>
                  <span>Понравилось...</span>
                  <div className={styles['also-liked-avatars']}>
                    {isLiked && (
                      <CachedImage
                        key='current-user'
                        src={userInfo.photo_url}
                        alt={userInfo.name}
                        className={styles['also-liked-avatar']}
                      />
                    )}
                    {likedBy
                      .slice(
                        0,
                        isLiked
                          ? Math.min(2, likesCount - 1)
                          : Math.min(3, likesCount)
                      )
                      .map((user) => (
                        <CachedImage
                          key={user.id}
                          src={`${user.avatar}`}
                          alt={user.name}
                          className={styles['also-liked-avatar']}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
            {showComments ? (
              <Comments
                comments={productDetails?.comments || []}
                onClose={() => setShowComments(false)}
              />
            ) : (
              <>
                {!!category && (
                  <p id='product-category'>
                    <b>Категория:</b> {category}
                  </p>
                )}
                {!!hair_concerns && (
                  <p id='product-hair-concerns'>
                    <b>Тип волос:</b> {hair_concerns}
                    {hair_concerns_description &&
                      `. ${hair_concerns_description}`}
                  </p>
                )}
                <p
                  id='product-description'
                  className={styles['product-modal-description']}
                >
                  {description}
                </p>
                {!!usage_instructions && (
                  <p
                    id='product-usage-instructions'
                    className={styles['product-modal-description']}
                  >
                    <b>Инструкции:</b> {usage_instructions}
                  </p>
                )}
                {!!usage_step && (
                  <p
                    id='product-usage-step'
                    className={styles['product-modal-description']}
                  >
                    <b>Цель:</b> {usage_step}
                  </p>
                )}
              </>
            )}
          </div>

          {!showComments && (
            <div className={styles['product-buy-wrapper']}>
              {((type && type === 'guide') || price) && (
                <button
                  className={styles['product-modal-buy']}
                  onClick={handleBuy}
                >
                  {type && type === 'guide' && (
                    <span className={styles['buy-text']}>
                      {isHaveSubscriptions
                        ? 'Перейти к гайду'
                        : 'Получить доступ к гайду'}
                    </span>
                  )}
                  {price && <span className={styles['buy-price']}>Купить</span>}
                </button>
              )}
              <button
                className={styles['product-modal-chat']}
                onClick={() => {
                  startConversation(`Привет! Расскажи мне про "${title}"`);
                  document.querySelector('.App').classList.add('fadeOut');
                  window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
                  sendGTMEvent(GA4_KEY.open_ai_chat);
                  // window.Telegram.WebApp.openTelegramLink(URL_BOT);
                }}
              >
                <span className={styles['buy-text']}>Задать вопрос в чате</span>
              </button>
            </div>
          )}
          {showComments && (
            <div className={styles['product-сomments-wrapper']}>
              <button
                className={styles['product-modal-buy']}
                onClick={() => {
                  window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
                  setShowCommentForm(true);
                }}
              >
                <span className={styles['buy-text']}>Оставить комментарий</span>
              </button>
            </div>
          )}
          {showCommentForm && (
            <CommentForm
              postId={product.id}
              setProductsDetails={setProductsDetails}
              onClose={() => setShowCommentForm(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Product;

import React from 'react';

import { DEFAULT_USER } from '../../utils/constants';
import { formatDate } from '../../utils/common';

import styles from './Comments.module.css';

const Comments = ({ comments = [], onClose }) => {
  const currentUserId =
    window.Telegram?.WebApp?.initDataUnsafe?.user?.id || DEFAULT_USER.id;

  const sortedComments = [...comments].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const commentsList = sortedComments.map((comment, index) => {
    const isOwnComment = comment.user_id === currentUserId;

    return (
      <div
        key={`${comment.user_id}-${index}`}
        className={`${styles.commentItem} ${
          isOwnComment ? styles.ownComment : ''
        }`}
      >
        <div className={styles.commentHeader}>
          <span className={styles.username}>{comment.username}</span>
          <span className={styles.timestamp}>
            {formatDate(comment.created_at)}
          </span>
        </div>
        <p className={styles.commentContent}>{comment.content}</p>
      </div>
    );
  });

  return (
    <div className={styles.commentsSection}>
      <div className={styles.commentsHeader}>
        <h3 className={styles.commentsTitle}>Комментарии</h3>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
      </div>

      <div className={styles.commentsList}>
        {commentsList.length ? (
          commentsList
        ) : (
          <p className={styles.noComments}>Комментариев пока нет</p>
        )}
      </div>
    </div>
  );
};

export default Comments;

import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY, routes } from '../../utils/constants';

import { productsByCategory } from '../../Config';
import ProductItem from '../ProductItem/ProductItem';

import styles from './ProductList.module.css';

const ProductList = ({
  activeCategory,
  productsCatalog,
  selectedProduct,
  setSelectedProduct,
  setProductId,
}) => {
  const navigate = useNavigate();

  const handleBackButton = useCallback(() => {
    setSelectedProduct(null);
    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    window.Telegram.WebApp.setHeaderColor('#fff0f5');
    window.Telegram.WebApp.BackButton.hide();
  }, [setSelectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.setHeaderColor('#fff');
      window.Telegram.WebApp.BackButton.onClick(handleBackButton);
    } else {
      window.Telegram.WebApp.BackButton.hide();
    }

    return () => {
      if (window.Telegram.WebApp.BackButton.onClick) {
        window.Telegram.WebApp.BackButton.onClick(null);
      }
    };
  }, [selectedProduct, handleBackButton]);

  const openProductModal = useCallback(
    (product) => {
      setSelectedProduct(product);
      setProductId(product.id);

      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      navigate(routes.product(product.id));
      setTimeout(() => {
        sendGTMEvent(GA4_KEY.open_product(product.title, activeCategory));
      }, 0);
    },
    [activeCategory, navigate, setSelectedProduct, setProductId]
  );

  const products = React.useMemo(
    () =>
      activeCategory === 'products' && productsByCategory
        ? productsCatalog
        : productsByCategory[activeCategory],
    [activeCategory, productsCatalog]
  );

  return (
    <div className={styles.productListContainer}>
      <div className={styles.productList} key={activeCategory}>
        {products?.map((product, index) => (
          <ProductItem
            key={product?.id || `${activeCategory}-${index}`}
            product={product}
            onClick={() => openProductModal(product)}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(ProductList);
